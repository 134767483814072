import React from "react";
import "./chatbot.css";

const ThinkingIndicator = ({currentStep}) => (
  <div className="thinking-indicator-container">
    <div className="thinking-indicator">
      <div className="thinking-bubble">
        <div className="thinking-content">
          <div className="thinking-brain">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path 
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                className="thinking-circle"
              />
              <path 
                d="M12 6c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"
                className="thinking-pulse"
              />
            </svg>
          </div>
          <span className="thinking-text">{currentStep || 'AI is thinking'}</span>
          <div className="thinking-dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <div className="bot-avatar thinking-avatar">
      <img width={35} height={35} src="/chatbotAvatar.svg" alt="chatbot" />
    </div>
  </div>
);

export default ThinkingIndicator;