import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { customApi } from "../../services/api";
import "./Dashboard.css";
import { FaPen, FaPowerOff } from "react-icons/fa";
import { Header } from "../Header";
// import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { FiClock } from "react-icons/fi";
import { MonitorFlow } from "./Monitor-flow";
import { MonitorInfrastructure } from "./Monitor-Infrastructure";

const Dashboard = () => {
  const { user, logout } = useAuth();

  const navigate = useNavigate();
  const [flows, setFlows] = useState([]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Failed to logout:", error);
    }
  };

  useEffect(() => {
    const fetchFlowData = async () => {
      try {
        const flowData = (await customApi.getFlowDataAll()).data;
        console.log("flowData----->", flowData);

        setFlows(flowData);
      } catch (error) {
        console.error("Error fetching flow data:", error);
      }
    };

    fetchFlowData();
  }, []);

  const columns = [
    {
      name: "SI No",
      selector: (row) => row.si_no,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      grow: 2,
    },
    {
      name: "Active Plugins",
      selector: (row) => row.active_plugins,
      sortable: true,
      cell: (row) => (
        <span style={{ color: "#3366FF" }}>{row.active_plugins}</span>
      ),
    },
    {
      name: "Created At",
      selector: (row) => row.created_at,
      sortable: true,
    },
    {
      name: "Last Active Time",
      selector: (row) => row.last_active_time,
      sortable: true,
      cell: (row) => {
        return (
          <div>
            {entityData?.length > 0 ? (
              <>
                <span
                  style={{
                    backgroundColor: "#E6EFFF",
                    padding: "4px 8px",
                    borderRadius: "4px",
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  <FiClock
                    style={{
                      marginRight: "4px",
                    }}
                  />
                  {row.last_active_time}
                </span>
                {row.hug && (
                  <span
                    style={{
                      backgroundColor: "#F3E8FF",
                      color: "#9747FF",
                      padding: "2px 4px",
                      borderRadius: "4px",
                      marginLeft: "4px",
                      fontSize: "0.8em",
                    }}
                  >
                    {row.hug}
                  </span>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },

    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        return (
          <>
            {entityData?.length > 0 ? (
              <>
                <span
                  style={{
                    backgroundColor:
                      row.status === "On" ? "#DCFCE7" : "#FEE2E2",
                    color: row.status === "On" ? "#16A34A" : "#DC2626",
                    padding: "4px 8px",
                    borderRadius: "4px",
                  }}
                >
                  {row.status}
                </span>
              </>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];

  const entityData = [
    {
      id: 1,
      si_no: "01",
      name: "Lorem Ipsum Dolor Sit Amet",
      active_plugins: "10",
      created_at: "16/11/2024, 6:00 PM",
      last_active_time: "12 Hours 33 Minutes 44 Seconds",
      status: "Off",
    },
    {
      id: 2,
      si_no: "02",
      name: "Lorem Ipsum Dolor Sit Amet",
      active_plugins: "10",
      created_at: "16/11/2024, 6:00 PM",
      last_active_time: "12 Hours 33 Minutes 44 Seconds",
      status: "On",
    },
    {
      id: 3,
      si_no: "03",
      name: "Lorem Ipsum Dolor Sit Amet",
      active_plugins: "10",
      created_at: "16/11/2024, 6:00 PM",
      last_active_time: "12 Hours 33 Minutes 44 Seconds",
      status: "On",
    },
    {
      id: 4,
      si_no: "04",
      name: "Lorem Ipsum Dolor Sit Amet",
      active_plugins: "10",
      created_at: "16/11/2024, 6:00 PM",
      last_active_time: "12 Hours 33 Minutes 44 Seconds",
      status: "Off",
    },
    {
      id: 5,
      si_no: "05",
      name: "Lorem Ipsum Dolor Sit Amet",
      active_plugins: "10",
      created_at: "16/11/2024, 6:00 PM",
      last_active_time: "12 Hours 33 Minutes 44 Seconds",
      status: "Off",
    },
    {
      id: 5,
      si_no: "06",
      name: "Lorem Ipsum Dolor Sit Amet",
      active_plugins: "10",
      created_at: "16/11/2024, 6:00 PM",
      last_active_time: "12 Hours 33 Minutes 44 Seconds",
      status: "Off",
    },
    {
      id: 5,
      si_no: "05",
      name: "Lorem Ipsum Dolor Sit Amet",
      active_plugins: "10",
      created_at: "16/11/2024, 6:00 PM",
      last_active_time: "12 Hours 33 Minutes 44 Seconds",
      status: "Off",
    },
    {
      id: 5,
      si_no: "05",
      name: "Lorem Ipsum Dolor Sit Amet",
      active_plugins: "10",
      created_at: "16/11/2024, 6:00 PM",
      last_active_time: "12 Hours 33 Minutes 44 Seconds",
      status: "Off",
    },
    {
      id: 5,
      si_no: "05",
      name: "Lorem Ipsum Dolor Sit Amet",
      active_plugins: "10",
      created_at: "16/11/2024, 6:00 PM",
      last_active_time: "12 Hours 33 Minutes 44 Seconds",
      status: "Off",
    },
    {
      id: 5,
      si_no: "10",
      name: "Lorem Ipsum Dolor Sit Amet",
      active_plugins: "10",
      created_at: "16/11/2024, 6:00 PM",
      last_active_time: "12 Hours 33 Minutes 44 Seconds",
      status: "Off",
    },
  ];

  if (!user) {
    return <div>Loading user information...</div>;
  }

  return (
    <div className="dashboard-container">
      <Header />
      <main className="main-content">
        <MonitorFlow filteredFlows={flows} />
       {/* { <MonitorInfrastructure entityData={entityData} columns={columns} />} */}
      </main>
    </div>
  );
};

export default Dashboard;
