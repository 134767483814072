import React, { useEffect, useState } from "react";

export const ChatHistoryComponent = ({ onClick }) => {
  const [sessions, setSessions] = useState([]);

  // fetch sessions using http://localhost:3011/agent/api/chat api, pass Authorization header with value Bearer, get token from local storage
  useEffect(() => {
    getSessions();
  }, []);

  const getSessions = () => {
    const url = window.location.href.includes("localhost")
      ? "http://localhost:3011/agent/api/chat"
      : "https://" + window.location.hostname + "/agent/api/chat";
    fetch(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    }).then((res) => {
      res.json().then((data) => {
        console.log(data);
        setSessions(data);
      });
    });
  };

  // show sessions in a list on click of a session, call onClick function with session Object
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        padding: 10,
        zIndex: 1000,
        background: "white",
      }}
    >
      <div className="chat-window-panel" style={{ width: "100%", minWidth: 0 }}>
        <div class="chat-header">
          <div class="header-content">
            <div class="bot-avatar">
              <img width="40" height="40" src="/chatbot.svg" alt="chatbot" />
            </div>
            <div class="bot-info">
              <h3 class="bot-name">Chat History</h3>
            </div>
          </div>
        </div>
        <ul className="space-y-2 w-64 bg-white rounded-lg shadow-lg p-4 overflow-auto" style={{maxHeight: 'calc(100vh - 200px)'}}>
          {sessions.map((session) => (
            <li
              key={session.sessionId}
              onClick={() => onClick(session)}
              className="px-4 py-3 rounded-md hover:bg-gray-100 transition-colors cursor-pointer text-gray-700 hover:text-gray-900 flex items-center"
            >
              <span className="flex-1">{session.name}</span>
              <svg
                className="w-4 h-4 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
